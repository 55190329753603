export enum SheetViewSelector {
	Sheet,
	ActionNotes
}

export enum SheetViewStatus {
	Loading,
	NoSheet,
	Sheet,
	NoActionNotes,
	ActionNotes
}

export interface SheetActionNotes {
	sheetId: string;
	actionId: number;
	performedDate: string;
	actionType: string;
	status: string;
	notes: string;
	actionedBy: string;
	editedBy: string;
	modifiedDateTime: string; 
	skippedReason: string; 
}

export enum SheetGranularity {
	FiveMinutes = 5,
	FifteenMinutes = 15,
	ThirtyMinutes = 30,
	SixtyMinutes = 60
}

export const fiveMinuteIntervalMinuteValues = '0,5,10,15,20,25,30,35,40,45,50,55';